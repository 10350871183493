import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/inicio",
    // redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/inicio",
        name: "inicio",
        component: () => import("@/views/Inicio.vue"),
      },
      {
        path: "/",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "sedes/administrar",
            name: "profile-locations",
            component: () =>
              import("@/views/crafted/pages/profile/AdminLocationsProfile.vue"),
          },
          {
            path: "sedes/editar/:id",
            name: "editar-sedes",
            component: () =>
              import("@/views/crafted/pages/profile/EditarSedes.vue"),
          },
          {
            path: "equipos",
            name: "profile-equipos",
            component: () => import("@/views/crafted/pages/equipos/Index.vue"),
          },
          {
            path: "equipos/:id",
            name: "profile-equipos-ver",
            component: () =>
              import("@/views/crafted/pages/equipos/VerEquipo.vue"),
          },
          {
            path: "equipos/editar/:id",
            name: "profile-equipos-editar",
            component: () =>
              import("@/views/crafted/pages/equipos/EditarEquipo.vue"),
          },
          {
            path: "areas/crear",
            name: "crear-area",
            component: () =>
              import("@/views/crafted/pages/equipos/CrearArea.vue"),
          },
          {
            path: "areas/editar/:id",
            name: "editar-area",
            component: () =>
              import("@/views/crafted/pages/profile/EditarArea.vue"),
          },
          {
            path: "areas/ordenar/:id",
            name: "ordenar-area",
            component: () =>
              import("@/views/crafted/pages/profile/CambiarOrdenAreas.vue"),
          },
          {
            path: "equipos/ubicacion/crear",
            name: "profile-equipos-ubicacion-crear",
            component: () =>
              import("@/views/crafted/pages/equipos/CrearUbicacion.vue"),
          },
          {
            path: "bitacora",
            name: "profile-campaigns",
            component: () => import("@/views/crafted/pages/bitacora/Index.vue"),
          },
          {
            path: "bitacora/chat/:id",
            name: "profile-bitacora-chat",
            component: () =>
              import("@/views/crafted/pages/bitacora/chatRespuesta.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "informes/crear-informe",
            name: "crear-informe",
            component: () =>
              import("@/views/crafted/pages/informes/CrearInforme.vue"),
          },
          {
            path: "editar-informe/:id",
            name: "editar-informe",
            component: () =>
              import("@/views/crafted/pages/informes/EditarInforme.vue"),
          },
          {
            path: "informes/descargar-informe",
            name: "descargar-informe",
            component: () =>
              import("@/views/crafted/pages/informes/DescargarInforme.vue"),
          },
          {
            path: "informes",
            name: "profile-informes",
            component: () => import("@/views/crafted/pages/informes/Index.vue"),
            // redirect: 'profile-informes-mosaico',
            children: [
              {
                path: "",
                name: "profile-informes-mosaico",
                component: import("@/views/crafted/pages/informes/Mosaico.vue"),
              },
              {
                path: "lista",
                name: "profile-informes-lista",
                component: () =>
                  import("@/views/crafted/pages/informes/InformesTabla.vue"),
              },
              {
                path: "form/:_id?",
                name: "profile-informes-form",
                component: () =>
                  import("@/views/crafted/pages/informes/Form.vue"),
              },
            ],
          },
          {
            path: "mi-grupo",
            name: "profile-mi-grupo",
            component: () => import("@/views/crafted/pages/grupos/MiGrupo.vue"),
          },
          {
            path: "ver-invitados/:id",
            name: "ver-invitados",
            component: () =>
              import("@/views/crafted/pages/profile/VerInvitados.vue"),
          },
          {
            path: "editar-invitados/:id",
            name: "editar-invitados",
            component: () =>
              import("@/views/crafted/pages/profile/EditarInvitado.vue"),
          },
          {
            path: "listar-invitados",
            name: "listar-invitados",
            component: () =>
              import("@/views/crafted/pages/grupos/ListarInvitados.vue"),
          },
          {
            path: "mi-grupo-agregar-invitado",
            name: "mi-grupo-agregar-invitado",
            component: () =>
              import("@/views/crafted/pages/grupos/MiGrupoAgregarInvitado.vue"),
          },
          {
            path: "mi-grupo-agregar-grupo",
            name: "mi-grupo-agregar-grupo",
            component: () =>
              import("@/views/crafted/pages/grupos/MiGrupoAgregarGrupo.vue"),
          },
          {
            path: "mi-grupo-editar-grupo/:id",
            name: "mi-grupo-editar-grupo",
            component: () =>
              import("@/views/crafted/pages/grupos/EditarGrupo.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth_passwrd_reset.vue"),
    children: [
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
